function goBack () {

  if (history.length > 2) {
    // if history is not empty, go back:
    window.history.back();
  } else {
    // go home:
    window.history.replaceState(null, null, '/');
    window.location.replace('/')
  }

}
